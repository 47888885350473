<template>
  <div class="iq-top-navbar">
    <div style="background-color: #003b7d;border-radius:10px 0 10px 0"
         class="iq-navbar-custom  d-flex align-items-center justify-content-between">
      <div class="iq-sidebar-logo">
        <div class="d-flex  align-items-center flex top-logo px-2">
          <router-link :to="{ path: '/' }" class="logo">
            <div class="iq-light-logo">
              <img src="../../../../assets/images/logo-white.svg" class="img-fluid" alt="logo">
            </div>
            <div class="iq-dark-logo">
              <img src="../../../../assets/images/logo-white.svg" class="img-fluid" alt="logo">
            </div>
          </router-link>
          <div style="display: flex; justify-content: flex-start; align-items: center;flex-direction: column;">
              <p style="color:#fff; font-size: 16px; margin: 0 10px">
              ({{ hotel.code }})
              </p>
          </div>
        </div>
      </div>
      <div class="iq-menu-horizontal">
        <div class="iq-sidebar-menu">
          <CollapseMenu :items="items" :open="true" :horizontal="true" :sidebarGroupTitle="false"
                        class="collapse-menu text-white"/>
          <HoverMenu :items="items" :sidebarGroupTitle="false" class="hover-menu"/>
        </div>
      </div>
      <nav style="gap:10px" class="d-flex align-items-center navbar-expand-lg navbar-light p-0">
        <div style="height: 45px"
             class=" toggle-menu bg-white rounded-lg align-items-center justify-content-center align-self-center px-2"
             v-if="$route.meta.layout !== 'two-sidebar'">
          <div class="wrapper-menu" @click="sidebarMini">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
          </div>
        </div>
        <select name="" class="py-2 px-1 border-0 rounded-lg" style="outline: none ; appearance: none" id=""
                @change="changeLang()" v-model="langSelected">
          <option :value="lang.value" v-for="(lang,index) in langsOptions" :key="index">{{
              lang.title
            }}
          </option>
        </select>
        <ul class="navbar-list">
          <li class="rounded" v-nav-toggle>
            <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
              <div class="img-avatar p-3 bg-white rounded d-flex align-items-center justify-content-center"
                   style="width: 50px;height: 50px; margin-right: 5px;">
                {{ userInfo.name[0] }}
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3">
                    <h5 class="mb-0 text-white line-height">{{ userInfo.name }}</h5>
                    <span class="text-white font-size-12">{{ $t('active-user') }}</span>
                  </div>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="btn btn-primary dark-btn-primary" href="javascript:void(0)" @click="logout" role="button">{{
                        $t('log-out')
                      }}<i class="ri-login-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import CollapseMenu from '../../menus/CollapseMenu'
import HoverMenu from '../../menus/HoverMenu'
import HorizontalItems from '../../../../FackApi/json/HorizontalMenu'
import { core, APPNAME } from '../../../../config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import langChange from '../../../../Mixins/langchange'

export default {
  name: 'HeaderStyle1',
  mixins: [langChange],
  components: {
    HoverMenu,
    CollapseMenu
  },
  data () {
    return {
      appName: APPNAME,
      items: HorizontalItems,
      langSelected: 'en',
      notification: [
        {
          image: require('../../../../assets/images/user/user-01.jpg'),
          name: 'Emma Watson Nik',
          date: 'Just Now',
          description: '95 MB'
        },
        {
          image: require('../../../../assets/images/user/user-02.jpg'),
          name: 'New customer is join',
          date: '5 days ago',
          description: 'Jond Nik'
        },
        {
          image: require('../../../../assets/images/user/user-03.jpg'),
          name: 'Two customer is left',
          date: '2 days ago',
          description: 'Jond Nik'
        },
        {
          image: require('../../../../assets/images/user/user-04.jpg'),
          name: 'New Mail from Fenny',
          date: '3 days ago',
          description: 'Jond Nik'
        }
      ],
      message: [
        {
          image: require('../../../../assets/images/user/user-01.jpg'),
          name: 'Nik Emma Watson',
          date: '13 jan'
        },
        {
          image: require('../../../../assets/images/user/user-02.jpg'),
          name: 'Lorem Ipsum Watson',
          date: '20 Apr'
        },
        {
          image: require('../../../../assets/images/user/user-03.jpg'),
          name: 'Why do we use it?',
          date: '30 Jun'
        },
        {
          image: require('../../../../assets/images/user/user-04.jpg'),
          name: 'Variations Passages',
          date: '12 Sep'
        },
        {
          image: require('../../../../assets/images/user/user-05.jpg'),
          name: 'Lorem Ipsum generators',
          date: '5 Dec'
        }
      ]
    }
  },
  methods: {
    sidebarMini () {
      core.triggerSet()
    },
    changeLang () {
      this.$i18n.locale = this.selectedLang
      localStorage.setItem('booking-lang', this.langSelected)
      window.location.reload()
    },
    logout () {
      localStorage.removeItem('booking-hotel-info')
      this.$router.push('/login')
    },
    ...mapActions({
      modeChange: 'Setting/layoutModeAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    })
  },
  computed: {
    ...mapGetters({
      cartItems: 'Ecommerce/cartState',
      removeToCart: 'Ecommerce/removeToCartAction',
      bookmark: 'Setting/bookmarkState',
      langsOptions: 'Setting/langOptionState',
      selectedLang: 'Setting/langState'
    })
  },
  mounted () {
    this.langSelected = this.$i18n.locale
    if (this.$i18n.locale === 'en') {
      this.modeChange({
        rtl: false,
        dark: false
      })
    } else {
      this.modeChange({
        rtl: true,
        dark: false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.iq-menu-horizontal {
  background-color: #003b7d;
}

.iq-menu-horizontal a {
  color: #fff !important;
}

.collapse-menu {
  @media (min-width: 1300px) {
    display: none;
  }
}

.iq-sidebar-menu .iq-menu.hover-menu {
  display: flex;
  @media (max-width: 1299px) {
    display: none !important;
  }
}

.toggle-menu{
  display: none;
  @media (max-width: 1308px) {
    display: flex;
  }
}
</style>
